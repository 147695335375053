<template>
  <div class="Login" :style="'min-height:' + divHeight + 'px; overflow-y: auto;'">
    <div class="page-nav">
      <header>
        <template>
          <i>
            <img class="bars" src="../../assets/imgs/logo.png" alt />
          </i>
          <span>Mix2Plus</span>
          <i>

          </i>
        </template>
      </header>
    </div>

    <div class="forgetpassword">
      <div class="shuru">
        <h1 class="title">登入</h1>
        <div class="inp">
          <van-field v-model="form.login_name" type="tel" placeholder="請輸入賬號" />
        </div>
        <div class="inp">
          <van-field v-model="form.login_password" type="password" placeholder="請輸入密碼" />
        </div>
      </div>
      <div class="btn">
        <button @click="loginToStock">繼續</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      divHeight:
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight,
      form: {
        login_name: "",
        login_password: "",
      },
    };
  },
  methods: {
    loginToStock() {
      if (this.form.login_name == "") {
        this.$toast("帳號不能為空！");
        return false;
      }
      if (this.form.login_password == "") {
        this.$toast("密碼不能為空！");
        return false;
      }
      this.$api
        .loginToStock({
          login_name: this.form.login_name,
          login_password: window.btoa(this.form.login_password),
        })
        .then(result => {
          if (result.status == 200) {
            sessionStorage.setItem("stock_account_alias_id", result.data.alias_id);
            sessionStorage.setItem("stock_account_token", result.data.token);
            this.$router.push("/home");
            this.$toast("登錄成功！");
          } else {
            this.$toast(result.msg);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.Login {
  width: 100%;
  color: #111827;

  .page-nav {
    width: 100%;
    height: 100px;

    header {
      width: 100%;
      height: 100px;
      padding: 0 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: white;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1;

      .fan,
      i {
        width: 20px;
      }

      .members,
      .bars,
      i {
        width: 50px;
      }

      .van-popover__wrapper {
        .van-button {
          padding: 0;
          background-color: unset;
          border: unset;
        }
      }
    }
  }
}

.page-content {
  width: 100%;
  min-height: calc(100vh - 100px);
  padding: 1em;
}

::v-deep .van-field__body {
  padding: 20px 0;

  input {
    color: #9fa9b6;
  }

  input::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder {
    color: #9fa9b6;
  }

  input:-moz-placeholder,
  textarea:-moz-placeholder {
    color: #9fa9b6;
  }

  input::-moz-placeholder,
  textarea::-moz-placeholder {
    color: #9fa9b6;
  }

  input:-ms-input-placeholder,
  textarea:-ms-input-placeholder {
    color: #9fa9b6;
  }
}

.forgetpassword {
  width: 100%;
  color: #9fa9b6;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-size: $font-size-l;
  }

  .shuru {
    width: 85%;
    margin-top: 3em;

    .title {
      font-size: 1.5em;
      color: #111827;
      margin-bottom: 1.5em;
    }

    .inp {
      background-color: white;
      padding: .25em 1em;
      border-radius: .7em;
      margin-bottom: 1em;
      display: flex;
      justify-content: space-around;
      align-items: center;
    }

    .van-cell {
      padding: 0;
      background: none !important;
    }
  }

  .btn {
    width: 85%;
    margin-top: 1.5em;

    button {
      width: 100%;
      padding: 35px 0;
      background-color: #2563EB;
      border-radius: .3em;
      border: none;
      color: #ffffff;
    }
  }
}
</style>